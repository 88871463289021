<template>
    <div id="courseTeaching">
        <div class="box">
            <div style="width: 100%;height: 60px;display: flex;justify-content: space-between;margin-top: 10px;">
            <div>
                <div v-for="(item,index) in daohangData" :key="index"  :class="indexYs == index?'whiteData':'default'" @click="daohangClick(index)">
                    {{ item.name }}
                </div>
            </div>
            <div>
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;" >编辑课程</el-button> -->
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;">开始上课</el-button> -->
            </div>
          </div>
          <div class="boxCneterData" >
           
            <p  style="padding-left: 20px;font-size: 14px;padding-top: 20px;padding-right: 20px;">
                <span style="font-weight: 800;font-size: 20px;cursor: pointer;" class="el-icon-arrow-left" @click="blackClick"></span> <span style=";font-size: 18px;font-weight: 600;cursor: pointer;"  @click="blackClick">{{ keName }} {{ className }}</span> 
                    <el-upload
                        style="float: right;"
                        :show-file-list="false"
                        :on-change="importData"
                        :on-success="onSuccess"
                        ref="uploadrefss"
                        accept=".xlsx"
                        :auto-upload="false" :multiple="true"
                        :limit="1">
                        <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;" size="mini">批量设置上课时间</el-button>
                    </el-upload>
                  
                
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;float: right;" size="mini" @click="onLoadeClick">下载模版</el-button>
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;float: right;" size="mini" @click="addClick">设置上课时间</el-button>
            </p>
            <el-table :data="tableData" stripe style="width: 100%;height:500px;margin-top: 50px;" max-height="500px">
            <el-table-column type="index" width="80" label="序号" align="center"/>
            <el-table-column prop="courseChapterName" label="章名称" align="center" />
            <el-table-column prop="courseSectionName" label="节名称"  width="150" align="center" />
            <el-table-column prop="startTime" label="开始上课时间"  width="180" align="center" />
            <el-table-column prop="endTime" label="上课结束时间"  width="180" align="center" />
            <el-table-column prop="classRoomName" label="上课教室" align="center" />
            <el-table-column prop="kehou" label="课后作业是否布置"  width="150" align="center" >
                <template #default="scope">
                    <span>{{ scope.row.courseExercises?'是':'否' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="percent" label="课后作业完成情况" align="center"  width="150" />
            <el-table-column prop="shiti" label="操作" align="center" >
                <template #default="scope">
                <span style="cursor: pointer;color:#76C8F6" @click="editClick(scope.row)">编辑</span>
                <span v-if="scope.row.courseExercises==true" style="cursor: pointer;color:#76C8F6;padding-left: 10px;" @click="exerCiseSocreClick(scope.row)">作业成绩</span>
              </template>
            </el-table-column>

          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination v-if="total>5" style="margin: 0 auto;text-align: center;" :page-size="pageSize" :current-page="pageIndex" layout="prev, pager, next" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
        </div>
        </div>
        <el-dialog
    v-model="dialogVisible"
    title="上课时间设置"
    width="30%"
    :before-close="handleClose">
    <div v-if="addEdit == 1" style="padding-left: 16px;">
        <p style="margin-top: 10px;">
            <span style="padding-left: 28px;">章名称：</span>
            <el-select v-model="zhangvalue"  class="m-2" placeholder="请选择" size="small" @change="zhangChange">
                <el-option
                v-for="item in zhangoptions"
                :key="item.id"
                :label="item.catalogueName"
                :value="item.id"
                />
            </el-select>
        </p>
        <p style="margin-top: 10px;">
            <span style="padding-left: 28px;">节名称：</span>
            <el-select v-model="jievalue"  class="m-2" placeholder="请选择" size="small">
    <el-option
      v-for="item in jieoptions"
      :key="item.id"
      :label="item.catalogueName"
      :value="item.id"
    />
  </el-select>
        </p>
    </div>
    <div v-if="addEdit == 2">
        <p style="font-size: 14px;padding-left: 28px;">章名称：{{ courseChapterName }}</p>
        <p style="font-size: 14px;padding-left: 28px;">节名称：{{ courseSectionName }}</p>
    </div>
        
        <p style="margin-top: 10px;">
            <span style="padding-left: 28px;">上课时间：</span>
            <el-date-picker
                        v-model="exercisesnDate"
                        size="small"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        :disabled-date="disabledDate"
                        />
        </p>
       
        <p style="margin-top: 10px;">
            <span style="padding-left: 28px;">上课教室：</span>
            <el-select v-model="classValue"  class="m-2" placeholder="请选择" size="small">
    <el-option
      v-for="item in Classoptions"
      :key="item.id"
      :label="item.classRoomName"
      :value="item.id"
    />
  </el-select>
        </p>
    <template #footer>
      <div style="text-align: center;" class="dialog-footer">
        <el-button @click="dialogVisible = false" round>取消</el-button>
        <el-button type="primary" style="background-color: #76C8F6;border: none;" @click="insterClick" round>
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
    </div>
</template>


<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,defineProps, ref} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
import * as echarts from 'echarts';
import { baseUrl } from "@/js/util.js";
import {f_get_classNumTeacherTaskPageList,get_select_classRoomList,get_select_courseChapterList,get_select_courseSectionList,f_update_teacherTask,DownloadTeacherTaskExcel,f_ImportExcel_teacherTask } from '@/js/classManagement'
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    
    setup (props,ctx) {
        const router = useRouter();
        const store = useStore();
        const uploadrefss = ref(null)
        const disabledDate = (v) => {
            return v.getTime() < Date.now() - 24 * 60 * 60 * 1000;
            };
        const state = reactive({
            input:'',
            value:'',
            baseUrl:baseUrl,
            value1:'',
            fileRaw:null,
            addEdit:1,
            classValue:'',
            headers: {
                token: getStorage("ftoken"),
                operate: true,
            },
            Classoptions:[],
            dialogVisible:false,
            formInline:{
                user:'',
                region:''
            },  
            exercisesnDate:[],
            alignTime:'',
            endTime:'',
            keName:getStorage('classDetailData').courseName,
            className:getStorage('classDetailData').className,
            courseChapterName:'',
            courseSectionName:'',
            tableData:[],
            zhangvalue:'',
            zhangoptions:[],
            jievalue:'',
            jieoptions:[],
            pageIndex:1,
            pageSize:5,
            total:0,
            radio1:'1',
            indexYs:3,
            rowObject:{},
            rowId:'',
            daohangData:[
                {
                    name:'数据'
                },{
                    name:'课后作业'
                },{
                    name:'考试'
                },{
                    name:'上课时间'
                },
            ],
           
        })


        const methods ={
            // 下载模板
            onLoadeClick(){
                DownloadTeacherTaskExcel().then(res=>{
                 let blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    console.log(blob); // res就是接口返回的文件流了
                            let objectUrl = URL.createObjectURL(blob);
                            console.log(objectUrl);
                            window.location.href = objectUrl
        })
            },
                // 文件上传
    importData(file,fileLists){
        state.fileRaw=file.raw;
        console.log(file,'文件上传',file.raw,state.fileRaw)
            let formData=new FormData()
                formData.append('classNumId',getStorage('classDetailData').id);
                formData.append('courseId',getStorage('classDetailData').courseId);
                formData.append('teacherId',getStorage('userId'));
                formData.append('file',state.fileRaw);
                f_ImportExcel_teacherTask(formData).then(res=>{
                    if(res.data.code==1){
                        ElMessage({
                            type: 'success',
                            message: '成功',
                            })
                        uploadrefss.value.clearFiles()
                        methods.f_get_classNumTeacherTaskPageList();

                    }else{
                        console.log(res.data.message,488)
                        ElMessage({
                            type: 'info',
                            message: res.data.message,
                            })
                            uploadrefss.value.clearFiles()
                        
                    }
                })
    },
            
            // 保存
            insterClick(){
                if (!state.zhangvalue || !state.jievalue || !state.exercisesnDate || !state.classValue) {
                    return  ElMessage({
                                    message: "请完善信息",
                                    type: "info",
                                    showClose: true,
                                    });
                }
                if (state.exercisesnDate.length>0) {
                    state.alignTime = state.exercisesnDate[0];
                    state.endTime = state.exercisesnDate[1];
                }
                let params = {};
                if (state.addEdit == 1) {
                     params = {
                    courseId:getStorage('classDetailData').courseId,
                    courseChapterId:state.zhangvalue,
                    courseSectionId:state.jievalue,
                    startTime:state.alignTime,
                    endTime:state.endTime,
                    classRoomId:state.classValue,
                    userId:getStorage('userId'),
                    classNumId:getStorage('classDetailData').id,
                    taskStatus:0
                }
                }else{
                    params = {
                    id:state.rowId,
                    courseId:getStorage('classDetailData').courseId,
                    courseChapterId:state.zhangvalue,
                    courseSectionId:state.jievalue,
                    startTime:state.alignTime,
                    endTime:state.endTime,
                    classRoomId:state.classValue,
                    userId:getStorage('userId'),
                    classNumId:getStorage('classDetailData').id,
                    taskStatus:0
                }
                }
                
                f_update_teacherTask(params).then(res=>{
                    if (res.data.code == 1) {
                        ElMessage({
                                    message: "成功",
                                    type: "success",
                                    showClose: true,
                                    });
                                    state.dialogVisible = false;
                                    methods.f_get_classNumTeacherTaskPageList();

                    }else{
                        ElMessage({
                                    message: res.data.message,
                                    type: "error",
                                    showClose: true,
                                    });
                    }
                })  
            },
            
            // 章下拉
            get_select_courseChapterList(){
                let params = {
                    courseId:getStorage('classDetailData').courseId,
                }
                get_select_courseChapterList(params).then(res=>{
                    if(res.data.code == 1){
                        state.zhangoptions = res.data.data;
                    }
                })
            },
            zhangChange(event){
                methods.get_select_courseSectionList(event);
            },
            get_select_courseSectionList(event){
                let params = {
                    courseChapterId:event,
                }
                get_select_courseSectionList(params).then(res=>{
                    if (res.data.code == 1) {
                        state.jieoptions = res.data.data;
                    }
                })
            },
            // 获取上课教师下拉
            get_select_classRoomList(){
                get_select_classRoomList().then(res=>{
                    if (res.data.code == 1) {
                        state.Classoptions = res.data.data;
                    }
                })
            },
            // 获取列表数据
            f_get_classNumTeacherTaskPageList(){
                let params = {
                    classNumId:getStorage('classDetailData').id,
                    courseId:getStorage('classDetailData').courseId,
                    teacherId:getStorage('userId'),
                    pageIndex:state.pageIndex,
                    pageSize:state.pageSize,
                }
                f_get_classNumTeacherTaskPageList(params).then(res=>{
                    if (res.data.code == 1) {
                        state.tableData = res.data.data.list;
                        state.total = res.data.data.totalCount.Value;
                    }
                })
            },
            blackClick(){
                router.push('/home/afterClassExercises')
            },
            exerCiseSocreClick(row){
                router.push({path:'/home/exerciseScore',query:{courseSectionId:row.courseSectionId,courseChapterName:row.courseChapterName,courseSectionName:row.courseSectionName}})
            },
            blackClick(){
                router.push('/home/courseList')
            },
            daohangClick(index){
                console.log(index);
                state.indexYs = index;
                if (state.indexYs == 0) {
                    router.push('/home/classDetails')
                }else if(state.indexYs == 1){
                    router.push('/home/afterClassExercises')
                }else if(state.indexYs == 2){
                    router.push('/home/examinationIndex')
                }else if(state.indexYs == 3){
                    router.push('/home/classTime')
                }

            },
            handleSizeChange(val){
                state.pageSize = val;
                methods.f_get_classNumTeacherTaskPageList();
            },
            handleCurrentChange(val){
                state.pageIndex = val;
                methods.f_get_classNumTeacherTaskPageList();
            },
            handleClose(){
                state.dialogVisible = false;
            },
            editClick(item){
                state.dialogVisible = true;
                state.rowObject = item;
                methods.get_select_classRoomList();
                state.addEdit = 2;
                state.rowId = item.id;
                state.exercisesnDate[0] = item.startTime;
                state.exercisesnDate[1] = item.endTime;
                state.courseChapterName = item.courseChapterName;
                state.courseSectionName = item.courseSectionName;
                state.zhangvalue = item.courseChapterId;
                state.jievalue = item.courseSectionId;
                state.classValue = item.classRoomId;
            },
            addClick(){
                state.dialogVisible = true;
                state.addEdit = 1;
                state.zhangvalue = '';
                state.jievalue = '';
                state.classValue = '';
                state.exercisesnDate = [];
                methods.get_select_courseChapterList();
                methods.get_select_classRoomList();
            },
        }
        onMounted(() => {
            methods.f_get_classNumTeacherTaskPageList();
        })
        return {
      ...toRefs(state),
      ...methods,
      disabledDate,
      uploadrefss
    };
    }
    }

</script>
<style  lang="scss" scoped>
#courseTeaching{
    width: 100%;
            height: 800px;
            background: #F7F8FA;;
            .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
            }
            .whiteData{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #fff;
        }
        .default{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #EDEDED;
        }
        .boxCneterData{
            width: 100%;
            height: 800px;
            background: #fff;
        }
}

</style>